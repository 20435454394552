<template>
    <div>
        <vx-card>
            <div slot="no-body" class="tabs-container px-6 pt-6">
                <div class="vx-row">
                    <!-- <h3 class="ml-2">Thông tin tài sản</h3> -->
                </div>
                <div class="vx-row">
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <label class="vs-input--label">Chi nhánh</label>
                        <v-select class="" v-model="branchModel" :clearable="false" :options="branchOptions"
                                  data-vv-validate-on="blur" :disabled="isUpdate" v-validate="'required'"
                                  name="branch">
                                  <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                        </v-select>
                        <span class="text-danger text-sm">{{ errors.first('branch') }}</span>
                    </div>
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <label class="vs-input--label">Nhân sự</label>
                        <v-select class="" v-model="employeeModel" :clearable="false" :options="employeeOptions"
                                  data-vv-validate-on="blur" :disabled="isUpdate" v-validate="'required'"
                                  name="employeeCode">
                                  <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                        </v-select>
                        <span class="text-danger text-sm">{{ errors.first('employeeCode') }}</span>
                    </div>
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <label class="vs-input--label">Ngày khiếu nại chấm công</label>
                        <datepicker :format="'dd/MM/yyyy'" v-model="dateInOutChange"
                                    :disabledDates="disabledFn"
                                    data-vv-validate-on="input" :disabled="isUpdate" v-validate="'required'"
                                    name="date"></datepicker>
                        <span class="text-danger text-sm">{{ errors.first('date') }}</span>
                    </div>
                </div>
                <div class="vx-row">
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <label class="vs-input--label">Ca làm việc</label>
                        <v-select class="" v-model="workShiftMode" :disabled="isUpdate" :clearable="false"
                                  :options="workShiftData"
                                  data-vv-validate-on="blur" v-validate="'required'" name="leaveStatus">
                                  <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                        </v-select>
                    </div>
                    <div class="vx-col lg:w-1/6 md:w-1/2 w-1/2 mt-2">
                        <label class="vs-input--label">Thời gian bắt đầu</label>
                        <v-select :value="info.fromHourString" :disabled="isUpdate" :clearable="false"
                                  :options="timeSpans"
                                  @input="fromHour => updateTimeSpan(info, fromHour, 'fromHour')" name="fromHour"
                                  v-validate="'required'"/>
                        <span class="text-danger text-sm">{{ errors.first('fromHour') }}</span>
                    </div>
                    <div class="vx-col lg:w-1/6 md:w-1/2 w-1/2 mt-2">
                        <label class="vs-input--label">Thời gian kết thúc</label>
                        <v-select :value="info.toHourString" :disabled="isUpdate" :clearable="false"
                                  :options="timeSpans"
                                  @input="toHour => updateTimeSpan(info, toHour, 'toHour')" name="toHour"
                                  v-validate="'required'"/>
                        <span class="text-danger text-sm">{{ errors.first('toHour') }}</span>
                    </div>

                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <label class="vs-input--label">Trạng thái</label>
                        <v-select class="" v-model="inOutStatusModel" :clearable="false" :options="inOutStatusOptions"
                                  data-vv-validate-on="blur" v-validate="'required'" name="leaveStatus"/>
                    </div>
                </div>
                <div class="vx-row">
                    <div class="vx-col md:w-1/2 w-full mt-5">
                        <vs-textarea class="w-full" label="Lí do" v-model="info.reason" :disabled="isUpdate"
                                     data-vv-validate-on="blur" v-validate="'required'" name="reason"/>
                        <span class="text-danger text-sm">{{ errors.first('reason') }}</span>
                    </div>
                    <div class="vx-col md:w-1/2 w-full mt-5">
                        <vs-textarea class="w-full" label="Người duyệt bình luận" v-model="info.comment"
                                     data-vv-validate-on="blur" name="comment"/>
                        <span class="text-danger text-sm">{{ errors.first('comment') }}</span>
                    </div>
                </div>
                <!-- Save & Reset Button -->
                <div class="vx-row">
                    <div class="vx-col w-full">
                        <div class="mt-8 flex flex-wrap items-center justify-end mb-5">
                            <vs-button class="ml-auto mt-2" @click="saveChanges">
                                {{ isUpdate ? 'Cập nhật' : 'Thêm mới' }}
                            </vs-button>
                            <vs-button class="ml-4 mt-2" type="border" color="warning" @click="inOutList">Quay lại
                            </vs-button>
                        </div>
                    </div>
                </div>
            </div>
        </vx-card>
    </div>
</template>

<script>

import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker';
import utils from "../../../components/utils";
import moment from 'moment';

export default {
    components: {
        vSelect,
        Datepicker
    },
    data() {
        return {
            user_data: null,
            activeTab: 0,
            isUpdate: false,
            isUpdateFace: false,
            branchOptions: [],
            employeeOptions: [],
            inOutStatusOptions: [
                {value: 0, label: 'Chờ'},
                {value: 1, label: 'Đã duyệt'},
                {value: 2, label: 'Từ chối'},
                {value: 3, label: 'Hủy bỏ'}
            ],
            durationOptions: [
                {value: 0, label: 'Cả ngày'},
                {value: 1, label: 'Thời gian cố định'}
            ],
            info: {},
            disabledFn: {
                customPredictor(date) {
                    if (date.getTime() >= new Date().getTime()) {
                        return true; // returns true if disabled
                    }
                }
            },
            workShiftSetting: [],
            timeSpans: [],
            file: '',
            fileName: 'Thêm dữ liệu khuôn mặt.',
            uploadPercent: 0,
            isShowPercent: false,
            workShiftData: [],
            dateInOutChange: null,
        }
    },
    async created() {
        let timeDuration = 30, timeSpans = [];
        for (let i = 0; i < 24 * 60; i = i + timeDuration) {
            timeSpans.push({value: i, label: utils.minuteToHour(i)});
        }
        this.timeSpans = timeSpans;
        let branchList = JSON.parse(localStorage.getItem('branchInfoList') || '[]');
        this.branchOptions = branchList.map(x => {
            return {label: x.organizationBranchName, value: x.id}
        });

        this.info = {
            organizationBranchId: this.$route.query.branch_id,
            employeeId: null,
            status: 0,
            duration: 0,
            workShiftId: null
        };
        await this.loadEmployee();
        if (this.$route.query.id) {
            this.$vs.loading();
            this.$crm.get(`/work-shift/employee/admin-find-update-in-out-by-id/${this.$route.query.id}`).then((response) => {
                this.$vs.loading.close();
                this.info = response.data;
                this.info.fromHourString = new Date(response.data.startHour).getHours() + ":" + new Date(response.data.startHour).getMinutes();
                this.info.fromHour = new Date(response.data.startHour).getMinutes() * 60 + new Date(response.data.startHour).getMinutes();
                this.info.toHourString = new Date(response.data.endHour).getHours() + ":" + new Date(response.data.endHour).getMinutes();
                this.info.toHour = new Date(response.data.endHour).getMinutes() * 60 + new Date(response.data.endHour).getMinutes();
                this.dateInOutChange = this.info.date;
                this.isUpdate = true;
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        }
    },
    watch: {
        'dateInOutChange': function () {
            this.info.date = this.dateInOutChange,
                this.changeDateInOut()
        },
    },
    computed: {
        branchModel: {
            get() {
                return {
                    label: this.branchOptions.find(x => x.value == this.info.organizationBranchId).label,
                    value: this.info.organizationBranchId
                }
            },
            set(obj) {
                this.info.organizationBranchId = obj.value;
                if (!this.isUpdate) this.clearWorkShift();
                this.loadEmployee()
            }
        },
        employeeModel: {
            get() {
                return {
                    label: this.getLabelString(this.employeeOptions.find(x => x.value == this.info.employeeId)),
                    value: this.info.employeeId
                }
            },
            set(obj) {
                this.info.employeeId = obj.value;
                if (!this.isUpdate) this.clearWorkShift();

            }
        },
        inOutStatusModel: {
            get() {
                return {
                    label: this.getLabelString(this.inOutStatusOptions.find(x => x.value == this.info.status)),
                    value: this.info.status
                }
            },
            set(obj) {
                this.info.status = obj.value;
            }
        },
        workShiftMode: {
            get() {
                return {
                    label: this.getLabelString(this.workShiftData.find(x => x.value == this.info.workShiftId)),
                    value: this.info.workShiftId
                }
            },
            set(obj) {
                this.info.workShiftId = obj.value;
            }
        },
        validateForm() {
            return !this.errors.any()
        },
    },
    methods: {
        clearWorkShift() {
            this.info.workShiftId = null;
            this.info.fromHour = null;
            this.info.fromHourString = null;
            this.info.toHour = null;
            this.info.toHourString = null;
        },
        getWorkShiftByEmployee() {
            let searchInfo = {
                organizationBranchId: parseInt(this.info.organizationBranchId),
                employeeId: this.info.employeeId ? [this.info.employeeId] : null,
                start: moment(this.info.date).set({hour: 0, minute: 0, second: 0, millisecond: 0}),
                end: moment(this.info.date).set({hour: 23, minute: 59, second: 59, millisecond: 59})
            }
            this.$vs.loading();
            this.$crm.post('/time-keeping/find', searchInfo).then((response) => {
                let workShiftData = response.data.sort((item1, item2) => {
                    if (moment(item1.date) > moment(item2.date)) return 1;
                    if (moment(item1.date) < moment(item2.date)) return -1;
                    // return item1.employeeCode > item2.employeeCode ? 1 : -1
                })
                this.workShiftData = workShiftData.map(item => {
                    item.label = item.groupName + " - " + item.workShiftName;
                    item.value = item.id;
                    return item;
                })
                this.$vs.loading.close();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        changeDateInOut() {
            if (!this.isUpdate) this.clearWorkShift();
            this.getWorkShiftByEmployee();
        },
        getLabelString(object) {
            return (object && object.label) || 'Chưa xác định';
        },
        updateTimeSpan(setting, hour, type) {

            setting[type] = hour.value;
            setting[`${type}String`] = hour.label;
        },
        loadEmployee() {
            return new Promise(resolve => {
                if (!this.info.organizationBranchId) return;

                this.$vs.loading();
                this.$crm.post('/employee/find-by-organization-branch/' + this.info.organizationBranchId).then((response) => {
                    this.employeeData = response.data;
                    this.$vs.loading.close();
                    if (response.data.length == 0) {
                        this.employeeOptions = []
                        return this.$vs.notify({
                            title: 'Error',
                            text: 'Không tồn tại nhân viên nào',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        });
                    }
                    this.employeeOptions = response.data.map(item => {
                        return {
                            value: item.employeeId,
                            label: item.employeeCode + " " + item.fullName
                        }
                    });
                    this.employeeModel = this.employeeOptions[0]
                    resolve(true);
                }).catch((err) => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        title: 'Error',
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            });
        },
        async saveChanges(evt) {

            evt.preventDefault();
            try {
                if (!this.validateForm) return;

                const result = await this.$validator.validateAll();
                if (!result) return;
                if (this.info.workShiftId == null)
                    return this.$vs.notify({
                        title: 'Error',
                        text: 'Vui lòng chọn ca làm việc và xem lại ngày khiếu nại chấm công.',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                let updateInOut = {
                    updateInOutId: this.info.id,
                    employeeWorkShiftByDayId: this.info.workShiftId,
                    startHour: new Date(new Date().setHours(0, this.info.fromHour, 0, 0)),
                    endHour: new Date(new Date().setHours(0, this.info.toHour, 0, 0)),
                    employeeId: this.info.employeeId,
                    comment: this.info.comment,
                    reason: this.info.reason,
                    date: this.info.date,
                    organizationBranchId: this.info.organizationBranchId,
                    status: this.info.status
                };

                let url = this.isUpdate ? `/work-shift/employee/assign-update-in-out/${this.info.status}` : '/work-shift/employee/admin-update-in-out';
                this.$vs.loading();
                this.$crm.post(url, updateInOut).then(() => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        color: 'success',
                        text: `${this.isUpdate ? 'Cập nhật' : 'Thêm'} thành công`,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                    });
                    this.inOutList();
                    this.$store.commit('UPDATE_IN_OUT', true);
                }).catch((err) => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });

            } catch (ex) {
                console.log(ex);
            }
        },
        inOutList() {
            this.$router.push(`/user/update-in-out?branch_id=${this.info.organizationBranchId}`).catch(() => {
            })
        }
    },
}
</script>

<style scoped>

</style>
